import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ClickOutsideDirective } from './multi-select.directive'; // Adjust the path as needed

@Component({
  selector: 'app-multi-select',
  standalone: true,
  imports: [CommonModule, CheckboxModule, FormsModule, ButtonModule, ClickOutsideDirective],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent {
  @Input() options: any[] = []; // Options for the multiselect
  @Input() optionLabel: string = 'label'; // Property name for labels
  @Input() optionValue: string = 'value'; // Property name for values
  @Input() selectedValues: any[] = []; // Selected values
  @Output() selectionChange = new EventEmitter<any[]>(); // Emits selection changes

  dropdownOpen = false; // Controls dropdown visibility

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  closeDropdown() {
    this.dropdownOpen = false;
  }

  getOptionLabel(option: any): string {
    return option[this.optionLabel] || '';
  }

  getOptionValue(option: any): any {
    return option[this.optionValue];
  }

  isSelected(value: any): boolean {
    return this.selectedValues.includes(value);
  }

  onOptionClick(option: any) {
    const value = this.getOptionValue(option);
    const index = this.selectedValues.indexOf(value);
    if (index === -1) {
      this.selectedValues.push(value); // Add to selection
    } else {
      this.selectedValues.splice(index, 1); // Remove from selection
    }
    this.selectionChange.emit(this.selectedValues); // Emit updated values
  }

  selectAll() {
    this.selectedValues = this.options.map(option => this.getOptionValue(option));
    
    this.selectionChange.emit(this.selectedValues);
  }

  clearAll() {
    this.selectedValues = [];
    this.selectionChange.emit(this.selectedValues);
  }
}
