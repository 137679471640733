
@if(from === 'profile'){
    <app-heading [title]="'Education'" [button]="headingButton" [isbreadcrumb]="true" [showToggle]="false"></app-heading>    
} @else{
    <div class="grid grid-nogutter mb-3">
        <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
            <h1 class="h4 m-0">Education</h1>
            
        </div>
        <div class="col-12 md:col-6 sm:col-12 flex md:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start sm:align-items-center  md:gap-4 gap-0 ">
            <p-button styleClass="vibrant-pink-background" label="Add a Degree" [rounded]="true" icon="pi pi-plus" size="large" (onClick)="showCreateSchool()"/>
        </div>
    </div>
    
}

<!-- No Data Education-->
@if(educationDataLength === 0){
    <div class="flex flex-column h-page-full">
        <div class="flex flex-column gap-1 h5 text-center">
            <span>Please enter any education and degree information you’d like to share with {{selectedTenant ? selectedTenant.firm.name : ''}}</span>
        </div>
    </div>
}

<!-- List/Edit Education-->
@if(educationDataLength > 0){
<p-accordion [multiple]="true" class="w-full">
    @for (education of educations; track education) {
        <p-accordionTab>
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-4 w-full">
                    <div class="flex flex-column line-height-2">
                        <span class="font-medium white-space-nowrap">
                            {{education.school.name}}
                        </span>
                        <span class="text-sm text-black-alpha-60">
                            {{education.degree.type}}
                        </span>
                    </div>
                    <div class="mx-auto ">
                        @if(status(education)){
                            <p-tag [value]="status(education)" [rounded]="true" />
                        }
                    </div>
                @if(!education.isLocked){
                    <div class="wrap-actions flex align-items-center gap-2">
                        <p-button (click)="navigateToEditScreen(education)"  [rounded]="true" [text]="true" icon="true">
                            <span class="material-symbols-outlined">
                                edit
                            </span>
                        </p-button>
                        <button pButton pRipple type="button" (click)="onDelete($event, education)" [rounded]="true" [text]="true" icon="true"
                            class="p-button-danger">
                            <span class="material-symbols-outlined"> delete </span>
                        </button>
                    </div>
                }
                </span>
            </ng-template>
            <div class="flex flex-column gap-3">
                <div class="grid">
                    <div class="col-6 lg:col-4 flex flex-column">
                        <span class="text-xs text-primary-900 font-medium">Institution</span>
                        <span class="text-black-alpha-60"> {{education.school.name}}</span>
                    </div>
                    <div class="col-6 lg:col-4 flex flex-column">
                        <span class="text-xs text-primary-900 font-medium">Degree</span>
                        <span class="text-black-alpha-60">{{education.degree.type}}</span>
                    </div>
                    <div class="col-6 lg:col-4 flex flex-column">
                        <span class="text-xs text-primary-900 font-medium">Major</span>
                        <span class="text-black-alpha-60">{{education.degree.major}}</span>
                    </div>
                    <div class="col-6 lg:col-4 flex flex-column">
                        <span class="text-xs text-primary-900 font-medium">Graduation Year</span>
                        <span class="text-black-alpha-60">{{education.school.startDate | date:'yyyy'}} -- {{education.school.endDate | date:'yyyy'}}</span>
                    </div>
                </div>
            </div>
        </p-accordionTab>
    }
  <!--   <p-accordionTab>
        <ng-template pTemplate="header">
            <span class="flex align-items-center gap-4 w-full">
                <div class="flex flex-column line-height-2">
                    <span class="font-medium white-space-nowrap">
                        Amy Elsner
                    </span>
                    <span class="text-sm text-black-alpha-60">
                        Bates College
                    </span>
                </div>
                <div class="mx-auto">
                    <p-tag value="Verified" [rounded]="true" />
                </div>
                <div class="mr-4">
                    <p-button [rounded]="true" [text]="true" icon="true" (onClick)="editMode($event)">
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </p-button>
                </div>

            </span>
        </ng-template>
        <div class="flex flex-column gap-3">
            <div class="grid">
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">Institution</span>
                    <span class="text-black-alpha-60">Harvard Law School</span>
                </div>
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">City</span>
                    <span class="text-black-alpha-60">Washington</span>
                </div>
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">Country</span>
                    <span class="text-black-alpha-60">USA</span>
                </div>
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">Degree</span>
                    <span class="text-black-alpha-60">Corporate Law</span>
                </div>
                <div class="col-6 lg:col-8 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">Major</span>
                    <span class="text-black-alpha-60">Litigation Services</span>
                </div>
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">GPA</span>
                    <span class="text-black-alpha-60">3.8</span>
                </div>
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">Graduation Year</span>
                    <span class="text-black-alpha-60">2012</span>
                </div>
            </div>
        </div>
    </p-accordionTab>
    <p-accordionTab>
        <ng-template pTemplate="header">
            <span class="flex align-items-center gap-4 w-full">
                <div class="flex flex-column line-height-2">
                    <span class="font-medium white-space-nowrap">
                        Amy Elsner
                    </span>
                    <span class="text-sm text-black-alpha-60">
                        Bates College
                    </span>
                </div>
                <div class="mx-auto">
                    <p-tag value="Verified" [rounded]="true" />
                </div>
                <div class="mr-4">
                    <p-button [rounded]="true" [text]="true" icon="true" (onClick)="editMode($event)">
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </p-button>
                </div>

            </span>
        </ng-template>
        <div class="flex flex-column justify-content-between gap-2">
            <div class="grid xl:w-9 w-full">
                <div class="col-12">
                    <p-floatLabel>
                        <input type="text" pInputText id="institutionName" />
                        <label for="institutionName">Institution Name</label>
                    </p-floatLabel>
                </div>
                <div class="lg:col-6 col-12">
                    <p-floatLabel>
                        <p-dropdown [options]="dummy" optionLabel="name" inputId="city" placeholder="Select a City" />
                        <label for="city">Select a City</label>
                    </p-floatLabel>
                </div>
                <div class="lg:col-6 col-12">
                    <p-floatLabel>
                        <p-dropdown [options]="dummy" optionLabel="name" inputId="country" placeholder="country" />
                        <label for="country">Country</label>
                    </p-floatLabel>
                </div>
                <div class="col-12">
                    <p-floatLabel>
                        <input type="text" pInputText id="degree" />
                        <label for="degree">Degree</label>
                    </p-floatLabel>
                </div>
                <div class="col-12">
                    <p-floatLabel>
                        <input type="text" pInputText id="major" />
                        <label for="major">Major</label>
                    </p-floatLabel>
                </div>
                <div class="lg:col-6 col-12">
                    <p-floatLabel>
                        <input type="text" pInputText id="gpa" />
                        <label for="gpa">GPA (Optional)</label>
                    </p-floatLabel>
                </div>
                <div class="lg:col-6 col-12">
                    <p-floatLabel>
                        <p-dropdown [options]="dummy" optionLabel="name" inputId="graduationYear"
                            placeholder="Graduation Year" />
                        <label for="graduationYear">Graduation Year</label>
                    </p-floatLabel>
                </div>
            </div>
            <div class="flex gap-3">
                <p-button label="Discard" rounded="true" outlined="true" size="large"
                    styleClass="white-space-nowrap"></p-button>
                <p-button label="Save" rounded="true" size="large" styleClass="white-space-nowrap"></p-button>
            </div>
        </div>
    </p-accordionTab>
    <p-accordionTab>
        <ng-template pTemplate="header">
            <span class="flex align-items-center gap-4 w-full">
                <div class="flex flex-column line-height-2">
                    <span class="font-medium white-space-nowrap">
                        Amy Elsner
                    </span>
                    <span class="text-sm text-black-alpha-60">
                        Bates College
                    </span>
                </div>
                <div class="mx-auto">
                    <p-tag value="Verified" [rounded]="true" />
                </div>
                <div class="mr-4">
                    <p-button [rounded]="true" [text]="true" icon="true" (onClick)="editMode($event)">
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </p-button>
                </div>

            </span>
        </ng-template>
        <div class="flex flex-column gap-3">
            <div class="grid">
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">Institution</span>
                    <span class="text-black-alpha-60">Harvard Law School</span>
                </div>
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">City</span>
                    <span class="text-black-alpha-60">Washington</span>
                </div>
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">Country</span>
                    <span class="text-black-alpha-60">USA</span>
                </div>
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">Degree</span>
                    <span class="text-black-alpha-60">Corporate Law</span>
                </div>
                <div class="col-6 lg:col-8 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">Major</span>
                    <span class="text-black-alpha-60">Litigation Services</span>
                </div>
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">GPA</span>
                    <span class="text-black-alpha-60">3.8</span>
                </div>
                <div class="col-6 lg:col-4 flex flex-column">
                    <span class="text-xs text-primary-900 font-medium">Graduation Year</span>
                    <span class="text-black-alpha-60">2012</span>
                </div>
            </div>
        </div>
    </p-accordionTab> -->
</p-accordion>
}

@if(from === 'onboarding'){
    <div class="flex gap-3 mt-3">
        <p-button
          label="Back"
          rounded="true"
          outlined="true"
          (onClick)="submit('back')"
          size="large"
          styleClass="white-space-nowrap"
        ></p-button>
        <p-button
          label="Next"
          rounded="true"
          size="large"
          [disabled]="educationDataLength === 0"
          styleClass="white-space-nowrap freshgreen-background"
          (onClick)="submit('next')"
        ></p-button>
      </div>
}

<!-- List/Edit Education End-->