<div class="multi-select-container p-multiselect" (clickOutside)="closeDropdown()">
  <div class="multi-select-header p-multiselect-header" (click)="toggleDropdown()">
    <span>{{ selectedValues.length > 0 ? selectedValues.length + ' selected' : 'Select Search Markets' }}</span>
    <i class="pi pi-chevron-down dropdown-icon" [class.open]="dropdownOpen"></i>
  </div>

  <div class="multi-select-dropdown p-multiselect-panel p-overlay p-component" *ngIf="dropdownOpen">
   <div class="p-overlay-content">
    <div class="multi-select-actions p-multiselect-footer justify-content-between" style="padding: 0px; height: 45px;">
    <div class="flex">
      
      <p-button [disabled]="selectedValues.length === options.length" class="select-all-btn " label="select all" [rounded]="true" [text]="true" icon="true" (onClick)="selectAll()">
      </p-button>
       
    </div>
    <div class="flex">
     
      <p-button [disabled]="selectedValues.length===0" [rounded]="true" [text]="true" icon="true" (onClick)="clearAll()">
          <span class="material-symbols-outlined">
              close
          </span>
      </p-button>
    
  </div>
    </div>

    <ul class="multi-select-options p-multiselect-items p-dropdown-items" style="margin:0px">
      <li class="p-element" *ngFor="let option of options" (click)="onOptionClick(option)">
        <span 
        class="multi-select-option p-multiselect-item p-element p-ripple p-dropdown-item ">
        <p-checkbox 
          [binary]="true" 
          [label]="getOptionLabel(option)" 
          [ngModel]="isSelected(getOptionValue(option))" 
          (click)="$event.stopPropagation(); onOptionClick(option)">
        </p-checkbox>
        </span>
      </li>
    </ul>

    </div>
  </div>
</div>
